import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import IntText from "../intText/IntText";
import { GET_GALLERY_STATE, GET_PAGE_BY_SLUG } from "../../helpers/queries";
import "./Page.scss";
import Gallery, { GalleryImg } from "../gallery/Gallery";

type PageProps = {
  setBackground: (a: GalleryImg[]) => void;
  isHome?: boolean;
};

type Image = {
  mediaItemUrl: string;
  altText: string;
};

const Page = ({ setBackground, isHome }: PageProps) => {
  const { id } = useParams();
  const [pageType, setPageType] = useState<String>();
  const { data: galleryState } = useQuery(GET_GALLERY_STATE);

  //TODO: Get gallery state and set position relative if not fullscreen
  const { data, loading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug: isHome ? "home" : id,
    },
    onCompleted: (data) => {
      setPageType(data.page?.pageSettings.pageType);
      if (
        data.page?.pageSettings.pageType === "page" &&
        data.page?.pageSettings.gallery.length > 0
      ) {
        setBackground(data.page?.pageSettings.gallery);
      } else {
        setBackground([]);
      }
    },
  });

  if (!loading) {
    return data?.page ? (
      <div
        className={`page type-${pageType} fullscreen-${galleryState.galleryFullscreen}`}
        key={data.page.slug}
      >
        {data.page.pageSettings.pageType === "project" &&
          data.page.pageSettings.gallery?.length > 0 && (
            <Gallery images={data.page.pageSettings.gallery} canOpen />
          )}
        {!isHome && id !== "home" && (
          <h1>
            <IntText de={data.page.title} en={data.page.content_en.titleEn} />
          </h1>
        )}

        <IntText de={data.page.content} en={data.page.content_en.textEn} />

        {(isHome || id === "home") &&
          data.page.homePage?.pageLinks?.map((page: any) => (
            <div className="preview" key={page.id}>
              <IntText
                de={page.previewTexts.previewDe}
                en={page.previewTexts.previewEn}
              />
            </div>
          ))}

        {data.page.pageSettings.logos?.length && (
          <div className="logoContainer">
            {data.page.pageSettings.logos.map((img: Image) => (
              <img
                key={img.mediaItemUrl}
                src={img.mediaItemUrl}
                alt={img.altText}
              />
            ))}
          </div>
        )}
      </div>
    ) : (
      <Navigate to="/" />
    );
  } else return null;
};

export default Page;
