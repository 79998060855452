import { InMemoryCache, makeVar } from "@apollo/client";

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        menuOpen: {
          read() {
            return menuOpen();
          },
        },
        galleryFullscreen: {
          read() {
            return galleryFullscreen();
          },
        },
      },
    },
  },
});

export const menuOpen = makeVar<boolean>(false);
export const galleryFullscreen = makeVar<boolean>(false);
