import { useState, useEffect } from "react";

export function useResizer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    function handleSizeChange() {
      setIsMobile(window.innerWidth < 850);
    }

    window.addEventListener("resize", handleSizeChange);

    return () => window.removeEventListener("resize", handleSizeChange);
  }, []);

  return isMobile;
}
