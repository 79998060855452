import { gql } from "@apollo/client";

export const GET_PAGES = gql`
  query GetPages {
    pages {
      nodes {
        slug
        title
        content_en {
          titleEn
        }
      }
    }
  }
`;

export const GET_PAGE_BY_SLUG = gql`
  query GetPageBySlug($slug: ID!) {
    page(id: $slug, idType: URI) {
      title
      content
      content_en {
        textEn
        titleEn
      }
      isFrontPage
      pageSettings {
        pageType
        gallery {
          mediaItemUrl
          caption
          altText
          mediaDetailsEn {
            captionEn
            altTextEn
          }
          mediaDetails {
            height
            width
          }
        }
        logos {
          altText
          mediaItemUrl
        }
      }
      homePage {
        pageLinks {
          ... on Page {
            id
            previewTexts {
              previewDe
              previewEn
            }
          }
        }
      }
    }
  }
`;

export const GET_MENU_STATE = gql`
  query GetMenuState {
    menuOpen @client
  }
`;

export const GET_GALLERY_STATE = gql`
  query GetGalleryState {
    galleryFullscreen @client
  }
`;
