import enNs1 from "./en/ns1.json";
import deNs1 from "./de/ns1.json";

const resources = {
  en: {
    ns1: enNs1,
  },
  de: {
    ns1: deNs1,
  },
} as const;

export default resources;
