import "./App.scss";
import "./i18n/config";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Page from "./components/page/Page";
import Menu from "./components/menu/Menu";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Gallery, { GalleryImg } from "./components/gallery/Gallery";

function App() {
  const [backgroundImgs, setBackgroundImgs] = useState<GalleryImg[]>();

  return (
    <div className="App">
      {backgroundImgs && backgroundImgs.length > 0 && (
        <Gallery images={backgroundImgs} fullscreen />
      )}
      <Header inverted={!backgroundImgs?.length} />
      <div className="flexArea">
        <main>
          <Routes>
            <Route
              path="/"
              element={<Page setBackground={setBackgroundImgs} isHome />}
            ></Route>
            <Route
              path="/:id"
              element={<Page setBackground={setBackgroundImgs} />}
            ></Route>
            <Route
              path="/:category/:id"
              element={<Page setBackground={setBackgroundImgs} />}
            ></Route>
          </Routes>
        </main>
        <Menu />
      </div>
    </div>
  );
}

export default App;
