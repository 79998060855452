import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

export type IntTextProps = {
  de?: string;
  en?: string;
};

const IntText = ({ de, en }: IntTextProps) => {
  const { i18n } = useTranslation();

  const getText = useCallback(() => {
    if (i18n.language === "de") {
      return de || en || "";
    }
    if (i18n.language === "en") {
      return en || de || "";
    }

    return "";
  }, [i18n.language, de, en]);

  return <>{parse(getText())}</>;
};

export default IntText;
