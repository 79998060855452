import { Button } from "ariakit";
import i18n from "i18next";
import "./Header.scss";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { galleryFullscreen, menuOpen } from "../../cache";
import { useQuery } from "@apollo/client";
import { GET_GALLERY_STATE, GET_MENU_STATE } from "../../helpers/queries";

type HeaderProps = {
  inverted?: boolean;
};

const Header = ({ inverted }: HeaderProps) => {
  const [lng, setLng] = useState<string>(i18n.language);

  const { data: menuState } = useQuery(GET_MENU_STATE);
  const { data: galleryState } = useQuery(GET_GALLERY_STATE);

  useEffect(() => {
    i18n.changeLanguage(lng);
  }, [lng]);

  const buttonClicked = useCallback(() => {
    if (galleryState.galleryFullscreen) {
      galleryFullscreen(false);
    } else {
      menuOpen(!menuState.menuOpen);
    }
  }, [menuState, galleryState]);

  return (
    <header
      className={`${inverted || menuState.menuOpen ? "inverted" : ""} ${
        galleryState.galleryFullscreen ? "gallery" : ""
      }`}
    >
      <Link
        to="/"
        className="bna"
        onClick={() => {
          menuOpen(false);
          galleryFullscreen(false);
        }}
      >
        BNA
      </Link>
      <nav>
        {!galleryState.galleryFullscreen && (
          <Button
            as="div"
            className="lang"
            onClick={() => setLng(lng === "de" ? "en" : "de")}
          >
            {lng === "de" ? "EN" : "DE"}
          </Button>
        )}
        <Button
          as="div"
          className={`menu-btn${
            menuState.menuOpen || galleryState.galleryFullscreen ? " open" : ""
          }`}
          onClick={buttonClicked}
        >
          <span></span>
          <span></span>
          <span></span>
        </Button>
      </nav>
    </header>
  );
};

export default Header;
