import { Player } from "video-react";
import "./Gallery.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "ariakit";
import IntText from "../intText/IntText";
import { useQuery } from "@apollo/client";
import { GET_GALLERY_STATE } from "../../helpers/queries";
import { galleryFullscreen } from "../../cache";

export interface GalleryImg {
  mediaItemUrl: string;
  caption?: string;
  altText?: string;
  mediaDetailsEn: {
    captionEn?: string;
    altTextEn?: string;
  };
  mediaDetails: {
    height: number;
    width: number;
  };
}

type GalleryProps = {
  images: GalleryImg[];
  fullscreen?: boolean;
  canOpen?: boolean;
};

enum Directions {
  Next = "NEXT",
  Prev = "PREV",
}

const Gallery = ({ images, fullscreen }: GalleryProps) => {
  const [activeImg, setActiveImg] = useState<number>(0);
  const [invertButtons, setInvertButtons] = useState<boolean>(false);
  const galleryRef = useRef<HTMLDivElement>(null);
  const gap = "20px";

  const { data: galleryState } = useQuery(GET_GALLERY_STATE);

  useEffect(() => {
    if (
      galleryRef?.current &&
      images[activeImg].mediaDetails.width < galleryRef?.current?.offsetWidth
    ) {
      setInvertButtons(true);
    } else {
      setInvertButtons(false);
    }
  }, [images, activeImg, galleryRef]);

  const isButtonDisabled = useCallback(
    (dir: Directions) => {
      if (dir === Directions.Next) return !!(activeImg >= images.length - 1);
      if (dir === Directions.Prev) return !!(activeImg <= 0);
    },
    [activeImg, images]
  );

  const changeImg = useCallback(
    (dir: Directions) => {
      let num = activeImg;
      if (dir === Directions.Next && !isButtonDisabled(Directions.Next)) {
        num++;
      }
      if (dir === Directions.Prev && !isButtonDisabled(Directions.Prev)) {
        num--;
      }

      setActiveImg(num);
    },
    [activeImg, isButtonDisabled]
  );

  const getImageClass = useCallback(
    (img: GalleryImg, index: number) => {
      let position =
        index < activeImg ? "before" : index === activeImg ? "active" : "after";
      let format =
        img.mediaDetails.height > img.mediaDetails.width
          ? "portrait"
          : "landscape";

      return `${position} ${format}`;
    },
    [activeImg]
  );

  //TODO set slide transform based on img width

  return (
    <div
      ref={galleryRef}
      id="gallery"
      className={`${
        fullscreen || galleryState?.galleryFullscreen ? "fullscreen" : ""
      }`}
    >
      {images.map((img, index) => (
        <div
          key={img.mediaItemUrl}
          className={`imgWrapper ${getImageClass(img, index)}`}
        >
          {fullscreen || galleryState?.galleryFullscreen ? (
            <div
              className="image"
              style={{ backgroundImage: `url(${img.mediaItemUrl})` }}
            ></div>
          ) : (
            <Button
              as="div"
              onClick={() => galleryFullscreen(true)}
              className="image clickable"
              style={{ backgroundImage: `url(${img.mediaItemUrl})` }}
            >
              {/* <img alt={img.altText} src={img.mediaItemUrl}></img> */}
            </Button>
          )}
          <div className="caption">
            <IntText de={img.caption} en={img.mediaDetailsEn.captionEn} />
          </div>
        </div>
      ))}
      <nav className={invertButtons ? "inverted" : ""}>
        <Button
          as="div"
          disabled={isButtonDisabled(Directions.Prev)}
          onClick={() => changeImg(Directions.Prev)}
        >
          &#60;
        </Button>
        <Button
          as="div"
          disabled={isButtonDisabled(Directions.Next)}
          onClick={() => changeImg(Directions.Next)}
        >
          &#62;
        </Button>
      </nav>
    </div>
  );
};

export default Gallery;
