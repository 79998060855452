import { NavLink } from "react-router-dom";
import "./Menu.scss";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { Button } from "ariakit";
import { useQuery } from "@apollo/client";
import { GET_MENU_STATE, GET_PAGES } from "../../helpers/queries";
import IntText from "../intText/IntText";
import { useResizer } from "../../helpers/hooks";
import { menuOpen } from "../../cache";

export type MenuItem = { title: string; subMenu?: MenuItem[] };

const menuStructure: MenuItem[] = [
  {
    title: "programm",
    subMenu: [
      { title: "speedtest-676" },
      { title: "yestertoday" },
      { title: "raststaette-1km" },
      { title: "service-no-service" },
    ],
  },
  { title: "archiv" },
  {
    title: "ueber",
    subMenu: [{ title: "bert-neumann" }, { title: "bna" }],
  },
  { title: "haende-hoch" },
  { title: "kontakt" },
  { title: "impressum" },
  { title: "instagram" },
];

const Menu = () => {
  const { t } = useTranslation();
  const [openItem, setOpenItem] = useState<MenuItem>();
  const isMobile = useResizer();

  const { data: menuState } = useQuery(GET_MENU_STATE);
  const { data: pageStructure } = useQuery(GET_PAGES);

  const getItemTitles = useCallback(
    (slug: string) => {
      if (slug === "bna") return <span>BNA</span>;

      const page = pageStructure.pages.nodes.find((x: any) => x.slug === slug);
      if (page) return <IntText de={page.title} en={page.content_en.titleEn} />;
    },
    [pageStructure]
  );

  return (
    <nav id="menuWrapper" className={menuState.menuOpen ? "open" : ""}>
      <ul>
        {pageStructure &&
          menuStructure.map((item) => (
            <li key={item.title}>
              {item.subMenu ? (
                <>
                  <Button as="h2" onClick={() => setOpenItem(item)}>
                    {t(`menu.${item.title}`)}
                  </Button>
                  <ul
                    className={`submenu ${
                      item.title === openItem?.title ? "open" : ""
                    }`}
                  >
                    {item.subMenu.map((sub) => (
                      <li key={sub.title}>
                        <NavLink
                          to={`${item.title}/${sub.title}`}
                          onClick={
                            isMobile
                              ? () => menuOpen(!menuState.menuOpen)
                              : () => {}
                          }
                        >
                          {getItemTitles(sub.title) || sub.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </>
              ) : item.title === "instagram" ? (
                <h2>
                  <a
                    href="https://www.instagram.com/bna.ssociation/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(`menu.${item.title}`)}
                  </a>
                </h2>
              ) : (
                <NavLink
                  to={item.title}
                  onClick={
                    isMobile ? () => menuOpen(!menuState.menuOpen) : () => {}
                  }
                >
                  <h2>{t(`menu.${item.title}`)}</h2>
                </NavLink>
              )}
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default Menu;
