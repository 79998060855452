import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./resources";

export const defaultNS = "ns1";

i18next.use(initReactI18next).init({
  lng: "de", // if you're using a language detector, do not define the lng option
  debug: true,
  resources: resources,
  defaultNS,
});
